import React from "react";
import { Card, CardBody } from "shards-react";
import "../../../../assets/styles/dashboard.css";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

const DashboardCard = ({ bgColor, icone, label, shortName }) => {
  const history = useHistory();

  const labelClasses = classNames("stats-small__label", "title-span", "w-100");

  const redirectToTable = () => {
    history.push(`/umm/umm-linelist?module=${shortName}`);
  };

  return (
    <Card small style={{ height: "auto" }}>
      <div className="d-flex justify-content-center">
        <div style={{ backgroundColor: bgColor }} className="module-icon">
        <i className="material-icons">{icone}</i>
      </div>
      </div>
      
      <CardBody onClick={() => redirectToTable()} className="card-dashboard">
        <span className={labelClasses}>{label}</span>
        <div className="row users-data-roles p-4">
        <div className="col-4 text-center">
            <div className="d-flex justify-content-center">
              <div className="role-icon px-1">
                250
                <span className="mt-3">
                  <i className="material-icons rolle-icon">remove_red_eye</i>
                </span>
              </div>
            </div>
            <div>Views</div>
          </div>
          <div className="col-4 text-center">
            <div className="d-flex justify-content-center">
              <div className="role-icon px-1">
                234
                <span className="mt-3">
                  <i className="material-icons rolle-icon">work</i>
                </span>
              </div>
            </div>
            <div>Managers</div>
          </div>
          <div className="col-4 text-center">
            <div className="d-flex justify-content-center">
              <div className="role-icon px-1">
                255{" "}
                <span className="mt-3">
                  <i className="material-icons rolle-icon">lock</i>
                </span>
              </div>
            </div>
            <div>Admins</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export { DashboardCard };
