import React from 'react'
import {Col, Container, Row } from 'shards-react'
import ControlledAccordions from '../../components/accordion/JsonViewer'
import mappingJson from "../../assets/data/mapping.json"
import { useFetch } from '../../../../hooks/useFetch'
import { LinearProgress } from '@mui/material'
import { ErrorPage } from '../../../../pages/error'
import { useParams } from 'react-router-dom'

const ServiceMapping = () => {
    const {id} = useParams();
    const { data, error, isFetching } = useFetch(`api/intero/services/mappings/${id}`);

    if (error) {
        return (
        <ErrorPage />
        )
    }

  return (
        <Container fluid className="main-content-container">
             {isFetching ? (
                <>
                <LinearProgress />
                <div className="d-flex align-items-center justify-content-center py-4">
                    A carregar o mapeamento, aguarde...
                </div>
                </>
            ):(
                <Row className="w-100 h-100 p-4 mt-3">
                    <Col xs={12}>
                        <ControlledAccordions data={data && data.data}/>
                    </Col>
                </Row>
            )}
        </Container>
    )
}

export default ServiceMapping