import React from "react";

import "../../../assets/styles/moduleCard.css";
import { useHistory } from "react-router-dom";
import foto from "../../../assets/images/coronavirus.jpeg";
import foto1 from "../../../assets/images/card-image.jpg";
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from "@mui/material";
import { PlayArrow, SkipNext, SkipNextOutlined } from "@mui/icons-material";
import { CardBody, CardHeader, CardSubtitle, Col } from "shards-react";
import { useTranslation } from "react-i18next";

const ModuleCard = ({ module }) => {
  const history = useHistory();
  const { t } = useTranslation()

  const onSelection = () => {
    history.push(`/${module.route}`);
  };

  return (
    <Col xs={12} sm={10} md={8} lg={4} className="mt-4">
      <Card className="module-card shadow" sx={{ display: "flex" }}>
      <div className="info-btn">
          <i className="material-icons">info</i>
        </div>
        <div
          onClick={() => onSelection()}
          className="module-card__onFocus-wrapper"
        >
          <div className="content">
            <div className="w-100 d-flex justify-content-center">
              <i className="material-icons" style={{color: module.bgColor}}>{module.icon}</i>
            </div>
            <span className="text-center onHover-title mb-1">
            {t(module.route)}
            </span>
            <span className="module-desc text-center pb-2">
              Este módulo permite configurar, listar e executar serviços de sincronização de dados.
            </span>
          </div>
        </div>
        <div className="row">
        <CardMedia
          component="img"
          sx={{ width: 250 }}
          image={foto1}
          alt="Live from space album cover"
          className="col-5"
        />
        <Box className="col-7 p-0">
          <CardContent className="p-0">
          <div className='w-100  pt-2'>
                <div style={{ backgroundColor: module.bgColor }} className='module-card-icon mx-auto'>
                        <i className="material-icons">{module.icon}</i>
                    </div>
                    <CardHeader className='card__headerr text-center w-100'>{t(module.route)}</CardHeader>
                </div>
          </CardContent>
          <CardBody className='card__body pb-1'>
                    <CardSubtitle><i className="material-icons mr-1">access_time</i>{t("ultimo_acesso")}: <span className='ml-2'>Hoje</span></CardSubtitle>
                </CardBody>
        </Box>
        </div>
        
      </Card>
    </Col>
  );
};

export { ModuleCard };
