import React from "react";
import { Redirect } from "react-router-dom";
import { RequiredAuth } from "../utils/auth/RequiredAuth";
import { redirect } from "../utils/services/redirect";
import { Home } from "../pages/home";
import { Documentation } from "../pages/documentation";
import { Login } from "../pages/login";
import { UnauthorizedPage } from "../pages/unauthorized";
import UmmApp from "../modules/umm/app/App";
import MflApp from "../modules/mfl/app/App";
import NoSession from "../components/layout/defaultLayout/NoSession";
import { DefaultLayout } from "../components/layout/defaultLayout";
import ServicesApp from "../modules/srvce/app/App";

const routes = () => [
  {
    path: "/",
    exact: true,
    layout: NoSession,
    component: () => <Redirect to={redirect().redirectTo()} />,
  },
  {
    path: "/home",
    layout: DefaultLayout,
    isHomeLayout: true,
    exact: true,
    component: () => (
      <RequiredAuth>
        <Home />
      </RequiredAuth>
    ),
  },
  {
    path: "/documentation",
    layout: DefaultLayout,
    isHomeLayout: true,
    exact: true,
    component: () => (
      <RequiredAuth>
        <Documentation />
      </RequiredAuth>
    ),
  },
  {
    path: "/login",
    layout: NoSession,
    exact: true,
    component: Login,
  },
  {
    path: "/unauthorized",
    layout: NoSession,
    exact: true,
    component: UnauthorizedPage,
  },
  {
    path: "/umm",
    layout: NoSession,
    component: UmmApp,
  },
  {
    path: "/mfl",
    layout: NoSession,
    component: MflApp,
  },
  {
    path: "/services",
    layout: NoSession,
    component: ServicesApp,
  },
  {
    path: "/service",
    layout: NoSession,
    component: ServicesApp,
  },
];
export { routes };
