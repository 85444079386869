import React from "react";
import { Container, Col } from "shards-react";
import { useParams } from "react-router-dom";
import { modules } from "../../../../modules/modulesContent";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const { module } = useParams();

  const getModuleTitle = () => {
    if (module === "service") {
      return "Gestão de Serviços de Interoperabilidade";
    } else
      return modules.filter((element) => {
        return element.route === module;
      })[0].route;
  };

  return (
    <Container>
      <Col>
      <h5 className="mt-2">{(module && t(getModuleTitle())) || t("home")}</h5>
      </Col>
    </Container>
  );
};
