import React from 'react'
import { Modal, ModalBody } from 'shards-react'
import PersonIcon from '@mui/icons-material/Person';
import { Chip, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Col, Row } from 'react-bootstrap';

const Dialog = ({ open, setOpen, data }) => {

    const details = JSON.parse(data.importSummaries)

    console.log(details)
    return (
        <Modal open={open} toggle={() => setOpen()}>
            <ModalBody>
                <h5 className='mb-1'>General details</h5>
                <Divider />
                <article className='mb-3 mt-2'>
                    <div className='d-flex'>
                        <span className='mr-2'>Username</span><p>{data.username || "---"}</p>
                        <div className='mx-3' />
                        <span className='mr-2'>Status</span><p style={{ color: data.status === "ERROR"?"red":data.status === "WARNING" && "orange" }}>{data.status || "---"}</p>
                        <div className='mx-3' />
                        <span className='mr-2'>Created</span><p>{data.created_at || "---"}</p>
                    </div>
                </article>

                <article className='mb-3'>
                    <blockquote>
                        <span>Periodo: </span><p>{details.periods}</p>
                    </blockquote>
                    <blockquote>
                        <span>Unidades sanitárias: </span><p>{details.numOrgUnits}</p>
                    </blockquote>
                    <blockquote>
                        <span>Variaveis: </span><p>{details.numVariables}</p>
                    </blockquote>
                </article>


                <h5 className='mb-1'>Import details</h5>
                <Divider />
                <article className='mt-2'>
                    <div className='d-flex'>
                        <span className='mr-2'>Import type</span><p>{data.importType || "---"}</p>
                        <div className='mx-3' />
                        <span className='mr-2'>Import date</span><p >{data.importDate || "---"}</p>
                    </div>
                    <div className='mt-3'>
                        <Row>
                            <Col xs={6} className="pr-1">
                                <div className='w-100  imported py-2 d-flex justify-content-center align-items-center'>
                                    <div className='import-details'>
                                        <p>Imported</p>
                                        <span>{data.imported}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pl-1">
                                <div className='w-100  updated py-2 d-flex justify-content-center align-items-center'>
                                    <div className='import-details'>
                                        <p>Updated</p>
                                        <span>{data.updated}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className='mt-2'>
                            <Col xs={6} className="pr-1">
                                <div className='w-100  ignored py-2 d-flex justify-content-center align-items-center'>
                                    <div className='import-details'>
                                        <p>Ignored</p>
                                        <span>{data.ignored}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pl-1">
                                <div className='w-100  deleted py-2 d-flex justify-content-center align-items-center'>
                                    <div className='import-details'>
                                        <p>Deleted</p>
                                        <span>{data.deleted}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </article>
            </ModalBody>
        </Modal>
    )
}

export { Dialog }