import React, { useEffect} from "react";
import { Container, Row, Col, Card, CardHeader } from "shards-react";
import { useFetch } from "../../../../hooks/useFetch";
import ChartComponent from "../../components/chart/Chart";
import { ErrorPage } from "../../../../pages/error";
import MapHome from "../../components/map/map";
import { LinearProgress } from "@mui/material";
import "../../assets/styles/map.css";

const MFLHome = () => {

  //const orgUnits1 = useFetch(`/api/mfl/organisationUnits?paging=1`);
  const orgUnits = useFetch(`/api/mfl/organisationUnits?paging=${false}`);
  const { data, error, isFetching } = useFetch(`/api/cmam/mfl`);
  const provinces = useFetch("api/cmam/mfl?level=province");
  const dashboard = useFetch(`api/mfl/dashboard`);

  const getUserPrints = () => {
    const allUs = [];
    if (data)
      for (const org of data.organisationUnits) {
        allUs.push([org.name, org.province]);
      }
    return allUs;
  };

  useEffect(() => {
    getUserPrints();
  }, [isFetching]);

  if (error || provinces.error) {
    return <ErrorPage />;
  }


  return (
    <Container fluid className="main-content-container px-4 py-4">
      <Row>
        <Col lg="7" md="7" sm="7" className="mb-4">
          <Card small>
            <CardHeader
              style={{ flexDirection: "column" }}
              className="border-bottom d-flex align-itmes-center"
            >
              <h6 className="m-0 mb-2"> Mapa de Unidades Sanitárias</h6>

              <Row />
            </CardHeader>
          </Card>
          {orgUnits.isFetching && <LinearProgress />}

          <MapHome
            container={"mapHome"}
            coordinates={orgUnits.data && orgUnits.data.organisationUnits}
            zoom={4.61}
            legend={false}
          />
        </Col>
        <Col lg="5" md="5" sm="5" className="mb-4">
          <div className="mb-3">
            <ChartComponent
              title={"Unidades Sanitárias por tipo"}
              height="210"
              data={dashboard.data ? dashboard.data.healthFacilityTypes : null}
              loader={isFetching}
              chartType="bar"
              legend={false}
            />
          </div>

          <div>
            <ChartComponent
              title={"Unidades Sanitárias por nível"}
              height="180"
              data={dashboard.data ? dashboard.data.healthFacilityLevels : null}
              loader={isFetching}
              chartType="pie"
              legend={true}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MFLHome;
