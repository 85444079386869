import React from "react";
import dateFormat from "dateformat";
import { Container, Row, Col, Card, CardBody, Button, FormInput } from "shards-react";
import { useTranslation } from "react-i18next";
import SyncIcon from '@mui/icons-material/Sync';
import { Avatar, Fab, Skeleton } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import EnhancedTable from "../../../../components/commons/table/MaterialTable";
import { BASE_URL, requestHeader, useFetch } from "../../../../hooks/useFetch";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { ErrorPage } from "../../../../pages/error";
import { validateEmail } from "../../../../utils/commons/emailRegex";
import { useParams } from "react-router-dom";


const ServicesSync = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [emails, setEmails] = useState([])
  const [loadEmails, setLoadEmails] = useState(false);
  const [error, setError] = useState(false);
  const [refetch, setRefetch] = useState(false);




  const { data: nextSync, error: errorNext, isFetching: isFetchingNext } = useFetch(`/api/intero/services/nextSync/${id}`);
  const { data: lastSync, error: errorLast, isFetching: isFetchingLast } = useFetch(`/api/intero/services/lastSync/${id}`);

  console.log("lastSync: " + lastSync)
  console.log("nextSync: " + nextSync)

  if (errorNext || errorLast) {
    return <ErrorPage />
  }

  const [email, setEmail] = useState("");
  const [schedule, setSchedule] = useState("");


  const onSubmit = async () => {
    await axios.post(`${BASE_URL}/api/intero/schedulers`, { cronExpression: schedule, serviceId: id }, requestHeader())
      .then(() => {
        setSchedule("")
        setRefetch(!refetch)
      })
  }
  return (
    <Container fluid className="main-content-container px-4">
      <Row className="w-100 pt-4 mx-0">
        <Col xs={12} className="px-0">
          <Row className="w-100 mx-0 px-0">
            {
              (isFetchingNext || isFetchingLast) ?
                <>
                  <Col lg={12} className="">
                    <h6 style={{ fontWeight: "500" }} className="m-0 mb-2 ml-4 h6">{t("dados_sync")}</h6>

                  </Col>
                  <Col lg={6} className="mt-1 p-3 d-flex pb-4">
                    <Card className="w-100 pb-3">

                      <Col lg="1" md="1" sm="1" className=" d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={40} height={40} />
                      </Col>
                      <Col lg="10" md="10" sm="10" className="ml-3">
                        <h6 className="history-title" style={{ fontSize: "0.9rem" }}>
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                        </h6>
                        <p style={{ fontSize: "0.9rem" }}>
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </p>{" "}
                      </Col>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        className=" d-flex align-items-center"
                      >
                      </Col>
                    </Card>
                  </Col>
                  <Col lg={6}
                    className="mt-1 p-3  d-flex pb-4"
                  >
                    <Card className="w-100 pb-5">
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        className=" d-flex align-items-center justify-content-center"
                      >
                        <Skeleton variant="circular" width={40} height={40} />
                      </Col>
                      <Col lg="10" md="10" sm="10" className="ml-3">
                        <h6 className="history-title" style={{ fontSize: "0.9rem" }}>
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </h6>
                        <p style={{ fontSize: "0.9rem" }}>
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </p>{" "}
                        <p style={{ fontSize: "0.9rem" }}>
                          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </p>{" "}
                      </Col>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        className=" d-flex align-items-center"
                      >
                      </Col>
                    </Card>
                  </Col>
                </> :
                <>
                  <Col lg={12} className="">
                    <h6 style={{ fontWeight: "500" }} className="m-0 mb-2 ml-3 h5">{t("dados_sync")}</h6>
                  </Col>

                  <Col lg={6}
                    className="mt-1 p-3 pb-4"
                  >
                    <Card className="p-4">
                      <div className="d-flex p-1">
                        <Col
                          lg="1"
                          md="1"
                          sm="1"
                          className=" d-flex align-items-center justify-content-center"
                        >
                          <Avatar className="next-sync_avatar">
                            <SyncIcon />
                          </Avatar>
                        </Col>
                        <Col lg="10" md="10" sm="10" className="ml-3">
                          <h6 className="history-title" style={{ fontSize: "0.9rem" }}>
                            {t("last_sync")}
                          </h6>
                          <p style={{ fontSize: "0.9rem" }}>
                            {lastSync.data.date ? dateFormat(lastSync.data.date, "dd-mm-yyyy HH:MM:ss") : "---"}
                          </p>{" "}
                        </Col>
                        <Col
                          lg="1"
                          md="1"
                          sm="1"
                          className=" d-flex align-items-center"
                        >
                        </Col>
                      </div>
                    </Card>
                  </Col>

                  <Col lg={6}
                    className="mt-1 p-3 pb-4"
                  >
                    <Card className="p-4">
                      <div className="d-flex p-1">
                        <Col
                          lg="1"
                          md="1"
                          sm="1"
                          className=" d-flex align-items-center justify-content-center"
                        >
                          <Avatar className="next-sync">
                            <UpdateIcon />
                          </Avatar>
                        </Col>
                        <Col lg="10" md="10" sm="10" className="ml-3">
                          <h6 className="history-title" style={{ fontSize: "0.9rem" }}>
                            {t("next_sync")}
                          </h6>
                          <p style={{ fontSize: "0.9rem" }}>
                            {nextSync.data.date ? dateFormat(nextSync.data.date, "dd-mm-yyyy HH:MM:ss") : "---"}
                          </p>{" "}
                        </Col>
                        <Col
                          lg="1"
                          md="1"
                          sm="1"
                          className=" d-flex align-items-center"
                        >
                        </Col>
                      </div>
                    </Card>
                  </Col>
                </>
            }
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesSync;
