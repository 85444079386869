import React, { useContext } from "react";
import { Container, Row } from "shards-react";
import { ModuleCard } from "../../components/commons/card/ModuleCard";
import { AuthContext } from "../../context/AuthProvider";
import { modules } from "../../modules/modulesContent";
import ukwaba from "../../assets/images/ukwaba-logo.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation()

  return (
    <Container fluid className="main-content-container px-4 py-5">
      <div className="w-100 mt-5">
        <div className="text-center col-lg-12">
          <img className="mb-3" src={ukwaba} alt="SIVE" height="120" />
           <p className="h5 mb-3  ">
          {t("ukwaba_welcome")}
          </p>
          <span className="module__select-label ml-1">
            {" "}
            {t("seleccionar_modulo")}
          </span>
        </div>

        <Row className="w-100 mt-5 mx-0 d-flex justify-content-center">
          {modules.map(
            (module, index) =>
              auth &&
              (auth.roles.includes(module.necessaryRole) ||
                module.necessaryRole === "public") && (
                <ModuleCard module={module} />
              )
          )}
        </Row>
      </div>
    </Container>
  );
};

export default Home;
