import React from "react";
import { RequiredAuth } from "../../../utils/auth/RequiredAuth";
import { DefaultLayout } from "../../../components/layout/defaultLayout/index";
import SericesHome from "../pages/home/ServicesHome";
import MFLHistory from "../../mfl/pages/history/MFLHistory";
import { ServicesLineList } from "../pages/linelist";
import { ServicesSync } from "../pages/synchronization";
import { ServiceMapping } from "../pages/mapping";

const routes = () => [
  {
    path: "/:module/services-home",
    layout: DefaultLayout,
    component: () => (
      <RequiredAuth allowedRoles={["monitoring_sircev"]}>
        <SericesHome />
      </RequiredAuth>
    ),
  },
  {
    path: "/:module/linear-list/:id",
    layout: DefaultLayout,
    component: () => (
      <RequiredAuth allowedRoles={["monitoring_sircev"]}>
        <ServicesLineList />
      </RequiredAuth>
    ),
  },
  {
    path: "/:module/sync/:id",
    layout: DefaultLayout,
    component: () => (
      <RequiredAuth allowedRoles={["monitoring_sircev"]}>
        <ServicesSync />
      </RequiredAuth>
    ),
  },
  {
    path: "/:module/mapping/:id",
    layout: DefaultLayout,
    component: () => (
      <RequiredAuth allowedRoles={["monitoring_sircev"]}>
        <ServiceMapping />
      </RequiredAuth>
    ),
  },
  // {
  //   path: "/:module/api-documentation",
  //   layout: DefaultLayout,
  //   component: () => (
  //     <RequiredAuth allowedRoles={["monitoring_sircev"]}>
  //       <APIDocumentation />
  //     </RequiredAuth>
  //   ),
  // },
  // {
  //   path: "/:module/mfl-history",
  //   layout: DefaultLayout,
  //   component: () => (
  //     <RequiredAuth allowedRoles={["monitoring_sircev"]}>
  //       <MFLHistory />
  //     </RequiredAuth>
  //   ),
  // },
  // {
  //   path: "/:module/mfl-details/:id",
  //   layout: DefaultLayout,
  //   component: () => (
  //     <RequiredAuth allowedRoles={["monitoring_sircev"]}>
  //       <FacilityDetails />
  //     </RequiredAuth>
  //   ),
  // },
];
export { routes };
