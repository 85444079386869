import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { arrayItemRemover } from '../../../../utils/commons/arrayItemRemover';
import { useState } from 'react';

export default function ControlledAccordions({ data }) {

    const expandedFields = [];
    for (const field of data) {
        expandedFields.push(field.label)
    }
    const [expanded, setExpanded] = useState(expandedFields);
    const [changed, setChanged] = useState(false);

    const handleChange = (panel) => {
        if (expanded.includes(panel)) {
            const newValues = arrayItemRemover(expanded, panel)
            setExpanded(newValues);
            setChanged(!changed)
        } else {
            setExpanded(prevState => [...prevState, panel])
        }
    };

    return (
        <div className='border' style={{ borderRadius: "0.25rem" }}>
            {
                data && data.map((mappingJson, index) => (
                    <Accordion className={index === 0 ? "first-accordion" : index === (data.length - 1) ? "last-accordion" : ""} expanded={!expanded.includes(mappingJson.label)} onChange={() => handleChange(mappingJson.label)}>
                        <AccordionSummary
                            expandIcon={expanded === mappingJson.label ? <RemoveIcon fontSize='small' /> : <AddIcon fontSize='small' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <h6 style={{ fontWeight: "500", width: '33%', flexShrink: 0 }} className="h6"> {mappingJson.label} </h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                <pre>{JSON.stringify(mappingJson, null, 2)}</pre>
                            }
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    );
}