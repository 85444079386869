import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, FormSelect, Row } from "shards-react";
import { useFetch } from "../../../../hooks/useFetch";
import { LinearProgress } from "@mui/material";
import { ErrorPage } from "../../../../pages/error";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import { TableDataExporter } from "../../../../components/commons/dataExporter/TableDataExporter";
import Table from "../../../../components/commons/table/Table";
import { TablePaginationDemo } from "../../../../components/commons/pagination/Pagination";


const SericesHome = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const { data, error, isFetching } = useFetch(`/api/intero/services?page=${currentPage + 1}&pageSize=${rowsPerPage}`);


  if (error) {
    return (
      <ErrorPage />
    )
  }

  const tableColums = () => {
    return ['Id', 'nome', 'desc', 'tipo', 'data-criacao'];
  }

  const tableData = () => {
    const services = [];
    if (isFetching === false) {
      for (const service of data.services) {
        services.push([service.id, service.name, service.description, service.type, dateFormat(service.created_at, 'dd-mm-yyyy HH:MM:ss')])
      }
    }
    return services
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row>
        <Col>
          <Card small className="mb-4 mt-4">
            <CardHeader style={{ flexDirection: 'column' }} className="border-bottom d-flex align-itmes-center">
              <h6 className="m-0 mb-2"> {t("lista-servico")} </h6>
              <Row className='w-100 d-flex align-items-center mx-0'>
                <Col className='pl-0'>
                  <FormSelect
                    className="col-lg-3 mr-1"
                    id="project"
                  >
                    <option value='todos'>{t("filtrar-tipo")}</option>
                  </FormSelect>
                </Col>
                {data && <TableDataExporter fileName='lista de servicos' fileTitle='Serviço - Lista de Servicos' tableColums={tableColums} tableData={tableData} />}
              </Row>
            </CardHeader>
            {isFetching && <LinearProgress />}
            <CardBody className="p-0 pb-3">
              <Table origin="service-home" isRowClickable={true} allData={data && (data.data || data)} isFetching={isFetching} error={error} tableColumns={tableColums} tableData={tableData} />
            </CardBody>
            {isFetching === false && <TablePaginationDemo totalPages={data} setCurrentPage={setCurrentPage} currentPage={currentPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SericesHome;
