import React, { useContext } from "react";
import { Nav } from "shards-react";
import SidebarNavItem from "./SidebarNavItem";
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router-dom'
import { AuthContext } from "../../../../context/AuthProvider";

const SidebarNavItems = () => {

  const { t } = useTranslation();
  const { auth } = useContext(AuthContext)
  const {module, id} = useParams();

  const sidebarItems = () => {
    switch (module) {
      case 'covid': return [
        {
          title: t("pagina_inicial"),
          to: `/covid/covid-home`,
          htmlBefore: '<i class="material-icons">dashboard</i>',

        },
        {
          title: t("local"),
          htmlBefore: '<i class="material-icons">place</i>',
          to: `/covid/report-site`,
        },
        {
          title: t("utilizadores"),
          htmlBefore: '<i class="material-icons">people_alt</i>',
          to: `/covid/report-user`,
        },
        {
          title: t("historico"),
          htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
          to: `/covid/covid-linelist`,
        }
      ];
      case 'cmam': return [
        // {
        //   title: t("pagina_inicial"),
        //   to: '/cmam/cmam-home',
        //   htmlBefore: '<i class="material-icons">dashboard</i>',

        // },
        {
          title: t("historico"),
          htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
          to: '/cmam/cmam-linelist',
        },
        {
          title: t("prod_pagina"),
          htmlBefore: '<i class="material-icons">bubble_chart</i>',
          to: '/cmam/product',
        },
        {
          title: t("us"),
          htmlBefore: '<i class="material-icons">business</i>',
          to: '/cmam/orgunit',
        },
        {
          title: t("config_pagina"),
          htmlBefore: '<i class="material-icons">settings</i>',
          to: '/cmam/settings',
        }
      ];
      case 'sircev': return [
        {
          title: t("pagina_inicial"),
          to: '/sircev/sircev-home',
          htmlBefore: '<i class="material-icons">dashboard</i>',

        }, {
          title: t("historico"),
          htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
          to: '/sircev/sircev-linelist',
        },
      ];
      case 'umm': return [
        {
          title: "Dashboard",
          to: '/umm/umm-home',
          htmlBefore: '<i class="material-icons">dashboard</i>',
        }, {
          title: "Users",
          to: '/umm/umm-linelist',
          htmlBefore: '<i class="material-icons">people</i>',
        }
      ];
      case 'mfl': return [
        {
          title: "Dashboard",
          to: '/mfl/mfl-home',
          htmlBefore: '<i class="material-icons">dashboard</i>',
        }, {
          title: "Unidades Sanitárias",
          to: '/mfl/orgunits',
          htmlBefore: '<i class="material-icons">business</i>',
        }, {
          title: "API Documentation",
          to: '/mfl/api-documentation',
          htmlBefore: '<i class="material-icons">local_library</i>',
        }, {
          title: t("historico"),
          htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
          to: '/mfl/mfl-history',
        },
      ];
      case 'services':return[
        {
          title: "servicos",
          to: '/services/services-home',
          htmlBefore: '<i class="material-icons">settings</i>',
        },
      ];
      case 'service':return[
        {
          title: "historico",
          to: `/service/linear-list/${id}`,
          htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
        },
        {
          title: "agendar_sync",
          to: `/service/sync/${id}`,
          htmlBefore: '<i class="material-icons">bubble_chart</i>',
        },
        {
          title: "mapping",
          to: `/service/mapping/${id}`,
          htmlBefore: '<i class="material-icons">settings</i>',
        },
      ];
      default: return [
        {
          title: "modulos",
          to: '/home',
          htmlBefore: '<i class="material-icons">apps</i>',
        }, {
          title: "documentacao",
          to: '/documentation',
          htmlBefore: '<i class="material-icons">local_library</i>',
        }
      ];
    }
  }

  const home = () => {
    return {
      title: 'home',
      to: `/home`,
      htmlBefore: '<i class="material-icons">home</i>',
    }
  }

  const backToList = () => {
    return {
      title: "voltar_a_lista",
      to: `/services/services-home`,
      htmlBefore: '<i class="material-icons">arrow_back</i>',
    }
  }


  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column" style={{height:'95%'}}>
        {sidebarItems().map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
        {module === "service" && <SidebarNavItem marginTop='mt-auto' item={backToList()} /> }
        {module && auth && <SidebarNavItem item={home()} /> }
      </Nav>
    </div>
  )
}

export default SidebarNavItems;
