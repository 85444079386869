const modules = [
  // {
  //   shortName: "MFL",
  //   displayName: "Lista Mestre de Unidades Sanitárias",
  //   icon: "local_hospital",
  //   necessaryRole:'undefined'
  // },
/*   {
    shortName: "Covid-19",
    displayName: "Certificado de Vacinação contra Covid-19",
    icon: "coronavirus",
    necessaryRole: "monitoring_covid",
    route: "covid",
    bgColor: "#7ebbe4",
  },
  {
    shortName: "SIRCEV",
    displayName: "Módulo Hospitalar (MISAU) e SIRCEV",
    icon: "sick",
    necessaryRole: "monitoring_sircev",
    route: "sircev",
    bgColor: "#ea5b92",
  },
  {
    shortName: "CMAM",
    displayName: "SIS-MA e Ferramenta Central",
    icon: "vaccines",
    necessaryRole: "monitoring_cmam",
    route: "cmam",
    bgColor: "#19bb9d",
  }, */
  // {
  //   shortName: "UMM",
  //   displayName: "Módulo de Gestão de Utilizadores",
  //   icon: "people",
  //   necessaryRole: "public",
  //   route: "umm",
  //   bgColor: "#fa8035",
  // },
  // {
  //   shortName: "MFL - External",
  //   displayName: "Módulo de Listagem de Unidades Sanitárias",
  //   icon: "location_city",
  //   necessaryRole: "public",
  //   route: "mfl",
  //   bgColor: "#19bb9d",
  // },
  {
    shortName: "Services",
    displayName: "Gestão de Serviços de Sincronização de dados",
    icon: "settings",
    necessaryRole: "public", 
    route: "services",
    bgColor: "#C1666B"
  },
];

export { modules };
